.Navigation {
  font-size: 12px;
  width: 100vw;
  background-color: #22252e;
  text-align: left;
  position: fixed;
  top: 0;
  height: 38px;
}

.Navigation ul {
  padding-left: 0px;
  list-style-type: none;
}

.Navigation ul li {
  display: inline;
  background-color: #22252e;
  border-right: 1px solid #000000;
  padding: 10px;
  cursor: pointer;
}

.Navigation ul li.active {
  background-color: #292c35;
}

.Navigation ul li:hover,
.Navigation ul li.active:hover {
  background-color: #34393f;
}

.Navigation ul li span.file {
  color: #6e6f72;
}

.Navigation ul li span.close {
  visibility: hidden;
}

.Navigation ul li.active span.file {
  color: #ffffff;
}

.Navigation ul li.active span.close {
  visibility: visible;
}

.Navigation ul li:hover span.close {
  visibility: visible;
}

.Navigation ul li span.folder {
  color: #4f5257;
}

.Navigation ul li.active span.folder {
  color: #bbbcc1;
}
