.Explorer {
}

.Explorer .Title {
  background-color: #22252e;
  color: #ffffff;
  font-size: 10px;
  padding: 14px 10px;
  text-align: left;
}

.Explorer .FileExplorer {
  text-align: left;
}

.Explorer .FileExplorer ul.files {
  list-style: none;
  background-color: #22252e;
  padding: 0px;
  margin: 0px;
}

.Explorer .FileExplorer ul.files li.active {
  background-color: #292c35;
}

.divider {
  cursor: pointer;
  padding: 2px 0px;
  text-align: left;
}

.divider .title {
  font-weight: bold;
  color: #ffffff;
  font-size: 12px;
}

.divider .icon {
}
